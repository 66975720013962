import { navigate } from 'gatsby'
import { FC, useEffect } from 'react'

import { PageProps } from '../../types/types'

const NotFoundPage: FC<PageProps> = () => {
  useEffect(() => {
    navigate('/')
  }, [])

  return null
}

export default NotFoundPage
